import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";
import { unAuthenticatedRequest } from "../../libs/unAuthenticatedRequest";

export default function EmployerEdit() {
  const auth = useContext(AuthContext);
  const API_URL = "https://api.allaloner.se/v1/admin";
  const API_URL_V1 = "https://api.allaloner.se/v1";

  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [employer, setEmployer] = useState(null);
  const [yrke, setYrke] = useState("");
  const [inriktning, setInriktning] = useState("");

  let { id } = useParams();

  useEffect(() => {
    const getDatas = async () => {
      const data = await authenticatedRequest(auth).get(
        API_URL + "/employers/" + id
      );
      setEmployer(data);
      setInitialLoad(false);
    };
    getDatas();
  }, [id, API_URL]);

  const createEmployer = async () => {
    if (employer.name && employer.display_name && employer.slug) {
      setLoading(true);
      let updatedEmployer = {
        ...employer,
        bigImg: null,
        smallImg: null,
      };
      delete updatedEmployer.bigImg;
      delete updatedEmployer.smallImg;
      console.log(updatedEmployer);
      try {
        await authenticatedRequest(auth).patch(
          API_URL + "/employers/" + employer.id,
          updatedEmployer
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const uploadImage = async (event) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileupload", event.target.files[0]);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = (
        await axios.post(API_URL + "/image-upload", formData, config)
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadSmallLogo = async (event) => {
    let result = await uploadImage(event);
    setEmployer({
      ...employer,
      smallImg: { path: result.location },
      logo_small_id: result.id,
    });
    setLoading(false);
  };

  const uploadBigLogo = async (event) => {
    let result = await uploadImage(event);
    setEmployer({
      ...employer,
      bigImg: { path: result.location },
      logo_big_id: result.id,
    });
    setLoading(false);
  };

  const rebuildSpecificOccupation = async () => {
    if (yrke && inriktning) {
      let resp = await authenticatedRequest(auth).post(
        API_URL_V1 + "/paths/rebuild",
        {
          command: "arbetsgivare-slug-yrken-yrke",
          slug: employer.slug,
          yrke: yrke,
        }
      );

      let resp2 = await authenticatedRequest(auth).post(
        API_URL_V1 + "/paths/rebuild",
        {
          command: "arbetsgivare-slug-yrken-yrke-inriktning-inriktning",
          slug: employer.slug,
          yrke: yrke,
          inriktning: inriktning,
        }
      );
    }
    if (yrke && !inriktning) {
      let resp = await authenticatedRequest(auth).post(
        API_URL_V1 + "/paths/rebuild",
        {
          command: "arbetsgivare-slug-yrken-yrke",
          slug: employer.slug,
          yrke: yrke,
        }
      );
    }

    if (yrke) {
      let resp = await authenticatedRequest(auth).post(
        API_URL_V1 + "/paths/rebuild",
        {
          command: "arbetsgivare-slug",
          slug: employer.slug,
        }
      );
    }
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Uppdatera arbetsgivare
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  Här kan du uppdatera en arbetsgivare!
                </p>
              </div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8"></div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                {!initialLoad ? (
                  <div className="inline-block min-w-full py-2 align-middle">
                    <div className="shadow-sm border-t max-w-6xl">
                      <div className="flex flex-row">
                        <div className="px-10 mt-6 flex flex-col mr-10 border-r">
                          {/* <-- Name --> */}
                          <div className="w-full">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Officielt namn (official name)
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    name: e.target.value,
                                  })
                                }
                                value={employer.name}
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Display Name --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Visningsnamn (display name)
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={employer.display_name}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    display_name: e.target.value,
                                  })
                                }
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Slug --!> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Slug
                            </label>
                            <div className=" flex rounded-md shadow-sm">
                              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                https://loner.nu/arbetsgivare/
                              </span>
                              <input
                                type="text"
                                name="slug"
                                id="slug"
                                value={employer.slug}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    slug: e.target.value,
                                  })
                                }
                                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Org.nr --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Org.nr (swedish org number)
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={employer.org_nr}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    org_nr: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Registrator email --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Registrator email
                            </label>
                            <div className="mt-1">
                              <input
                                type="email"
                                name="registrator-email"
                                id="registrator-email"
                                value={employer.registrator_email}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    registrator_email: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Registrator emails --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Vid flera emailsadresser. Lägg i kommaseparerad
                              sträng
                            </label>
                            <div className="mt-1">
                              <input
                                type="email"
                                name="registrator-emails"
                                id="registrator-emails"
                                value={employer.registrator_emails}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    registrator_emails: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Employer url --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Webbplats
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="employer-url"
                                id="employer-url"
                                value={employer.url}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    url: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Employer Address --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Address
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="employer-address"
                                id="employer-address"
                                value={employer.address}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    address: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Employer zip --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Postkod
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="employer-zip"
                                id="employer-zip"
                                value={employer.zip}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    zip: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Employer City --> */}
                          <div className="w-full mt-5">
                            <label
                              htmlFor="first-name"
                              className=" block text-sm font-medium text-gray-700"
                            >
                              Stad
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="employer-city"
                                id="employer-city"
                                value={employer.city}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    city: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* <-- Description Name --> */}

                          <div className="w-full mt-5">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Beskrivning
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="about"
                                name="about"
                                rows={3}
                                value={employer.description}
                                onChange={(e) =>
                                  setEmployer({
                                    ...employer,
                                    description: e.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                              Some sentences about the company.
                            </p>
                          </div>
                        </div>
                        <div>
                          {/* <-- Small Logo --> */}
                          <div>
                            <div className="w-full mt-5 border-t py-5  flex flex-row">
                              <div className="">
                                <label
                                  htmlFor="photo"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Liten företags logga
                                </label>
                                <div className="mt-1 flex flex-col">
                                  <span className="h-12 w-12 ml-7 mt-2 overflow-hidden rounded-full bg-gray-100">
                                    {employer.smallImg ? (
                                      <img
                                        src={employer.smallImg.path}
                                        alt="small logo url"
                                      />
                                    ) : (
                                      <svg
                                        className="h-full w-full text-gray-300"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                      </svg>
                                    )}
                                  </span>
                                  <input
                                    className="mt-5"
                                    type="file"
                                    id="avatar"
                                    name="avatar"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => uploadSmallLogo(e)}
                                  ></input>
                                  {employer.smallImg ? (
                                    <a
                                      className="mt-5 text-blue-600"
                                      target="_blank"
                                      rel="noreferrer"
                                      href={employer.smallImg.path}
                                    >
                                      {employer.smallImg.path}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="w-full mt-5 border-t py-5  flex flex-row">
                              <div className="">
                                <label
                                  htmlFor="photo"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Stor företags logga
                                </label>
                                <div className="mt-1 flex flex-col">
                                  <span className="h-12 w-12 ml-7 mt-2 overflow-hidden rounded-full bg-gray-100">
                                    {employer.bigImg ? (
                                      <img
                                        src={employer.bigImg.path}
                                        alt="small logo url"
                                      />
                                    ) : (
                                      <svg
                                        className="h-full w-full text-gray-300"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                      </svg>
                                    )}
                                  </span>
                                  <input
                                    className="mt-5"
                                    type="file"
                                    id="avatar"
                                    name="avatar"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => uploadBigLogo(e)}
                                  ></input>
                                  {employer.bigImg ? (
                                    <a
                                      className="mt-5 text-blue-600"
                                      target="_blank"
                                      rel="noreferrer"
                                      href={employer.bigImg.path}
                                    >
                                      {employer.bigImg.path}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" mt-10 ml-10">
                        {!loading ? (
                          <button
                            type="button"
                            onClick={() => createEmployer()}
                            className="inline-flex mt-0 w-54 mb-10 items-center rounded-md border border-gray-300 bg-emerald-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-emerald-500 focus:outline-none"
                          >
                            Uppdatera arbetsgivare
                          </button>
                        ) : (
                          <FullWithLoader />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className=" mt-10">
                <label
                  htmlFor="yrkesSlug"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Skriv in yrkes slug
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="yrkesSlug"
                    value={yrke}
                    onChange={(e) => setYrke(e.target.value)}
                    id="yrkesSlug"
                    className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="lakare"
                  />
                </div>

                <label
                  htmlFor="yrkesSlug"
                  className="block text-sm mt-6 font-medium leading-6 text-gray-900"
                >
                  Skriv in yrkes inriktning slug
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={inriktning}
                    onChange={(e) => setInriktning(e.target.value)}
                    name="yrkesInriktningSlug"
                    id="yrkesInriktningSlug"
                    className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="skollakare"
                  />
                </div>
                <button
                  onClick={() => rebuildSpecificOccupation()}
                  className="px-4 mt-10 py-2 bg-blue-500 text-white rounded mb-40"
                >
                  Rebuild specific occuaption
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
