import { RefreshIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";
import { useNavigate } from "react-router-dom";

export default function LeadsList() {
  const API_URL = "https://api.allaloner.se/v1";
  const auth = useContext(AuthContext);

  let [expandedRows, setExpandedRows] = useState({});
  const [leads, setLeads] = useState([]);

  const refreshLeads = async () => {
    setLeads([]);
    const data = await authenticatedRequest(auth).get(API_URL + "/admin/leads");
    setLeads(data);
  };

  useEffect(() => {
    const getDatas = async () => {
      const data = await authenticatedRequest(auth).get(
        API_URL + "/admin/leads"
      );
      setLeads(data);
    };

    getDatas();
  }, [API_URL]);

  const expandRow = (i) => {
    let temp = expandedRows;

    if (expandedRows[i]) {
      delete temp[i];
    } else {
      temp[i] = true;
    }
    setExpandedRows({ ...temp });

    console.log(expandedRows);
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="pt-6">
          <div className="flex justify-between mx-auto px-2 sm:px-6 md:px-4">
            <h1 className="text-2xl font-semibold text-gray-900">Leads</h1>
          </div>
          <div className="px-4 mt-8 font-semibold text-sm">
            <div className="flex justify-between">
              <div className="mr-4">
                <button
                  className="bg-gray-100 py-1 px-1 ml-4 rounded-full"
                  onClick={() => refreshLeads()}
                >
                  <RefreshIcon className="inline-block text-gray-700 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full   align-middle">
                <div className="overflow-hidden shadow-sm border-t px-6 py-2">
                  <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Sök
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search"
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow-sm border-t">
                    {leads.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-300 table-auto">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Från
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                            >
                              Ämne
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Tid
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" bg-white">
                          {leads.toReversed().map((e, i) => (
                            <>
                              <tr className={"border-b py-2"}>
                                <td className="whitespace-nowrap px-3  text-sm text-gray-500">
                                  {e.from_address}
                                </td>
                                <td
                                  onClick={() => expandRow(i)}
                                  className="whitespace-nowrap cursor-pointer  pl-4 pr-3 text-sm text-gray-900 sm:pl-6 lg:pl-8"
                                >
                                  {e.phone}
                                </td>
                                <td className="whitespace-nowrap px-3  text-sm text-gray-500">
                                  {e.created_at.substring(0, 10)}{" "}
                                  {e.created_at.substring(11, 16)}
                                </td>
                              </tr>

                              {expandedRows[i] ? (
                                <tr className="border-b">
                                  <td
                                    className="px-6 text-gray-700 text-sm pb-5 pt-5"
                                    colSpan={4}
                                  >
                                    {e.message}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <FullWithLoader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
