import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";

export default function UnionsCreate() {
  const auth = useContext(AuthContext);
  const API_URL = "https://api.allaloner.se/v1/admin";

  const url = API_URL + "/unions";
  const [loading, setLoading] = useState(false);
  const [smallLogoUrl, setSmallLogoUrl] = useState(null);
  const [logo_small_id, setSmallLogoId] = useState(null);

  const [bigLogoUrl, setBigLogoUrl] = useState(null);
  const [logo_big_id, setBigLogoId] = useState(null);

  const [slug, setSlug] = useState(null);
  const [name, setName] = useState(null);
  const [display_name, setDisplayName] = useState(null);
  const [description, setDescription] = useState(null);
  const [org_nr, setOrgNr] = useState(null);

  const createEmployer = async () => {
    if (name && display_name && slug) {
      setLoading(true);
      let employer = {
        display_name,
        name,
        slug,
        description,
        org_nr,
        logo_small_id,
        logo_big_id,
      };
      console.log(employer);
      try {
        await authenticatedRequest(auth).post(API_URL + "/unions", employer);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const uploadImage = async (event) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileupload", event.target.files[0]);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = (
        await axios.post(API_URL + "/image-upload", formData, config)
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadSmallLogo = async (event) => {
    let result = await uploadImage(event);
    setSmallLogoUrl(result.location);
    setSmallLogoId(result.id);
    setLoading(false);
  };

  const uploadBigLogo = async (event) => {
    let result = await uploadImage(event);
    setBigLogoUrl(result.location);
    setBigLogoId(result.id);
    setLoading(false);
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Skapa nyt fackförbund
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  Här kan du skapa ett nytt fackförbund!
                </p>
              </div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8"></div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="shadow-sm border-t max-w-6xl">
                    <div className="flex flex-row">
                      <div className="px-10 mt-6 flex flex-col border-r mr-10">
                        {/* <-- Name --> */}
                        <div className="w-full">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Officielt namn (official name)
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              onChange={(e) => setName(e.target.value)}
                              autoComplete="given-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        {/* <-- Display Name --> */}
                        <div className="w-full mt-5">
                          <label
                            htmlFor="first-name"
                            className=" block text-sm font-medium text-gray-700"
                          >
                            Visningsnamn (display name)
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              onChange={(e) => setDisplayName(e.target.value)}
                              autoComplete="given-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        {/* <-- Slug --!> */}
                        <div className="w-full mt-5">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Slug
                          </label>
                          <div className=" flex rounded-md shadow-sm">
                            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                              https://loner.nu/arbetsgivare/
                            </span>
                            <input
                              type="text"
                              name="slug"
                              id="slug"
                              onChange={(e) => setSlug(e.target.value)}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        {/* <-- Org.nr --> */}
                        <div className="w-full mt-5">
                          <label
                            htmlFor="first-name"
                            className=" block text-sm font-medium text-gray-700"
                          >
                            Org.nr (swedish org number)
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              onChange={(e) => setOrgNr(e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        {/* <-- Description Name --> */}

                        <div className="w-full mt-5">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Beskrivning
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="about"
                              name="about"
                              rows={3}
                              onChange={(e) => setDescription(e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={""}
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Some sentences about the company.
                          </p>
                        </div>

                        {/* <-- Small Logo --> */}
                      </div>
                      <div>
                        <div className="w-full mt-5 py-5  flex flex-row">
                          <div className="">
                            <label
                              htmlFor="photo"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Liten företags logga
                            </label>
                            <div className="mt-1 flex flex-col">
                              <span className="h-12 w-12 ml-7 mt-2 overflow-hidden rounded-full bg-gray-100">
                                {smallLogoUrl ? (
                                  <img
                                    src={smallLogoUrl}
                                    alt="small logo url"
                                  />
                                ) : (
                                  <svg
                                    className="h-full w-full text-gray-300"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                  </svg>
                                )}
                              </span>
                              <input
                                className="mt-5"
                                type="file"
                                id="avatar"
                                name="avatar"
                                accept="image/png, image/jpeg, file/pdf"
                                onChange={(e) => uploadSmallLogo(e)}
                              ></input>
                              {smallLogoUrl ? (
                                <a
                                  className="mt-5 text-blue-600"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={smallLogoUrl}
                                >
                                  {smallLogoUrl}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="w-full mt-5 border-t py-5  flex flex-row">
                          <div className="">
                            <label
                              htmlFor="photo"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Stor företags logga
                            </label>
                            <div className="mt-1 flex flex-col">
                              <span className="h-12 w-12 ml-7 mt-2 overflow-hidden rounded-full bg-gray-100">
                                {bigLogoUrl ? (
                                  <img src={bigLogoUrl} alt="small logo url" />
                                ) : (
                                  <svg
                                    className="h-full w-full text-gray-300"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                  </svg>
                                )}
                              </span>
                              <input
                                className="mt-5"
                                type="file"
                                id="avatar"
                                name="avatar"
                                accept="image/png, image/jpeg"
                                onChange={(e) => uploadBigLogo(e)}
                              ></input>
                              {bigLogoUrl ? (
                                <a
                                  className="mt-5 text-blue-600"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={bigLogoUrl}
                                >
                                  {bigLogoUrl}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" mt-10 ml-10">
                      {!loading ? (
                        <button
                          type="button"
                          onClick={() => createEmployer()}
                          className="inline-flex mt-0 w-48 mb-10 items-center rounded-md border border-gray-300 bg-emerald-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-emerald-500 focus:outline-none"
                        >
                          Skapa fackförbund
                        </button>
                      ) : (
                        <FullWithLoader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
