import {
  ArchiveIcon,
  CheckCircleIcon,
  CheckIcon,
  DocumentIcon,
  DocumentRemoveIcon,
  PaperClipIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FullWithLoader from "../../components/FullWithLoader";
import SalaryReference from "../../components/SalaryReference";
import SmallLoader from "../../components/SmallLoader";
import SwitchToggle from "../../components/SwitchToggle";

import { AuthContext } from "../../contexts/authContext";
import { salaryTypes } from "../../data/constants";
import { authenticatedRequest } from "../../libs/authenticatedRequest";

export default function SalaryReferences() {
  const auth = useContext(AuthContext);
  const API_URL = "https://api.allaloner.se/v1";
  const [user, setUser] = useState(null);

  let [expandedRows, setExpandedRows] = useState({});
  const [references, setReferences] = useState([]);
  const [filteredReferences, setFilteredReferences] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [isLoadingArchive, setIsLoadingArchive] = useState({
    "09074809-063a-40a3-b5bb-80bc5115037c": false,
  });

  const [isLoadingElastic, setIsLoadingElastic] = useState({});

  const rebuildSalaryReferenceById = async (id) => {
    setIsLoadingElastic(true);
    let resp = await authenticatedRequest(auth).post(
      API_URL + "/admin/elastic/rebuild",
      {
        command: "single-reference",
        referenceId: id,
      }
    );
    setIsLoadingElastic(false);
    console.log(resp);
  };

  const removeSalaryReferenceById = async (id) => {
    let resp = await authenticatedRequest(auth).delete(
      API_URL + "/admin/elastic/salary-reference/" + id
    );
    console.log(resp);
  };

  const setArchived = async (reference) => {
    setIsLoadingArchive({ [reference.id]: true });
    reference.is_archived = true;
    let data = await authenticatedRequest(auth).patch(
      API_URL + "/salary-references/" + reference.id,
      { is_archived: reference.is_archived }
    );
    data = await authenticatedRequest(auth).get(API_URL + "/salary-references");

    data.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1
    );

    setReferences(data);
    setFilteredReferences(data);
    setIsLoadingArchive({ [reference.id]: false });
  };

  const setEnabled = async (reference) => {
    setIsLoading({ [reference.id]: true });
    reference.is_verified = !reference.is_verified;
    const data = await authenticatedRequest(auth).patch(
      API_URL + "/salary-references/" + reference.id,
      { is_verified: reference.is_verified }
    );

    setIsLoading({ [reference.id]: false });

    console.log(data);
  };

  const expandRow = (i) => {
    let temp = expandedRows;

    if (expandedRows[i]) {
      delete temp[i];
    } else {
      temp[i] = true;
    }
    setExpandedRows({ ...temp });

    console.log(expandedRows);
  };

  useEffect(() => {
    const getDatas = async () => {
      let data = await authenticatedRequest(auth).get(
        API_URL + "/salary-references?verified=false"
      );

      data = data.map((item) => {
        if (!item.title) {
          item.title = "no-title";
        }
        return item;
      });

      data.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1
      );

      setReferences(data);
      setFilteredReferences(data);
    };
    getDatas();
  }, [API_URL]);

  const inputChange = (event) => {
    if (event.target.value) {
      setFilteredReferences(
        references.filter((s) =>
          s.title.toLowerCase().startsWith(event.target.value)
        )
      );
    } else {
      setFilteredReferences(references);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Lönerapporter
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  Lista med rapporter
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full   align-middle">
                <div className="overflow-hidden shadow-sm border-t px-6 py-2">
                  <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Sök
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        onChange={(event) => inputChange(event)}
                        id="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search"
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow-sm border-t">
                    {filteredReferences.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Actions
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Salary
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {filteredReferences.map((e, i) => (
                            <React.Fragment key={"salrow" + i}>
                              <tr
                                key={e.id}
                                className={classNames(
                                  "  even:bg-blue-50",
                                  expandedRows[i] ? "" : ""
                                )}
                              >
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <span className="">
                                    {!isLoading[e.id] ? (
                                      e.is_verified ? (
                                        <button
                                          type="button"
                                          onClick={() => setEnabled(e)}
                                          className="inline-flex items-center rounded-full border border-transparent bg-emerald-700 p-1 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                          <CheckIcon
                                            className="h-4 w-4"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={() => setEnabled(e)}
                                          className="inline-flex items-center rounded-full border border-transparent bg-red-600 p-1 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                          <XIcon
                                            className="h-4 w-4"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      )
                                    ) : (
                                      <span className="inline-flex items-center ml-2">
                                        <SmallLoader />
                                      </span>
                                    )}
                                    {!isLoadingArchive[e.id] ? (
                                      <button
                                        type="button"
                                        onClick={() => setArchived(e)}
                                        className="inline-block ml-5 items-center rounded-full border border-blue-600 bg-blue-600 border-transparent p-1  text-white shadow-sm focus:outline-none"
                                      >
                                        <ArchiveIcon className="w-4 h-4 text-white" />
                                      </button>
                                    ) : (
                                      <span className="inline-flex items-center ml-6 mr-1">
                                        <SmallLoader />
                                      </span>
                                    )}
                                    <a
                                      href="https://loner.nu"
                                      target={"_blank "}
                                    >
                                      <button className="inline-block ml-5 items-center rounded-full border border-gray-400 bg-gray-200 border-transparent p-1  text-white shadow-sm focus:outline-none">
                                        <PaperClipIcon className="h-4 w-4 text-gray-800"></PaperClipIcon>
                                      </button>
                                    </a>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        removeSalaryReferenceById(e.id)
                                      }
                                      className="inline-block ml-5 items-center rounded-full border border-blue-600 bg-gray-600 border-transparent p-1  text-white shadow-sm focus:outline-none"
                                    >
                                      <DocumentRemoveIcon className="w-4 h-4 text-white" />
                                    </button>

                                    {!isLoadingElastic[e.id] ? (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          rebuildSalaryReferenceById(e.id)
                                        }
                                        className="inline-block ml-5 items-center rounded-full border border-blue-600 bg-blue-600 border-transparent p-1  text-white shadow-sm focus:outline-none"
                                      >
                                        <DocumentIcon className="w-4 h-4 text-white" />
                                      </button>
                                    ) : (
                                      <span className="inline-flex items-center ml-6 mr-1">
                                        <SmallLoader />
                                      </span>
                                    )}
                                  </span>
                                </td>

                                <td
                                  onClick={() => expandRow(i)}
                                  className="whitespace-nowrap cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                                >
                                  {e.id}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {e.title}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {e.salary} kr /{" "}
                                  {
                                    salaryTypes[e.salary_type]
                                      .display_name_short
                                  }
                                </td>
                              </tr>
                              {expandedRows[i] ? (
                                <tr
                                  className="bg-white cursor-pointer col-span-6"
                                  key={"expandable" + i}
                                >
                                  <td
                                    className="shadow-[inset_0_6px_2px_0px_rgba(0,0,0,0.05)] bg-white"
                                    colSpan={7}
                                  >
                                    <SalaryReference reference={e} />
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <FullWithLoader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
