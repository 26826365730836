import { Editor } from "@tinymce/tinymce-react";
import { useContext, useRef, useState } from "react";

export default function PublicEditor({
  editorRef,
  height,
  initialValue,
  setRequestHtml,
}) {
  const API_URL = "https://api.allaloner.se/v1/admin";
  const API_URL_V1 = "https://api.allaloner.se/v1";

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      branding={false}
      initialValue={initialValue}
      tinymceScriptSrc={"/tinymce/tinymce.min.js"}
      onEditorChange={() => setRequestHtml(editorRef.current.getContent())}
      init={{
        statusbar: false,
        menubar: false,
        branding: false,
        document_base_url: "https://allaloner.se/",
        relative_urls: false,
        remove_script_host: false,
        convert_urls: false,
        table_sizing_mode: "responesive",
        init_instance_callback: function (editor) {
          var freeTiny = document.querySelector(".tox .tox-notification--in");
          // freeTiny.style.display = "none";
        },
        height: height,
        plugins: [
          "emoticons",
          "autolink",
          "link",
          "autosave",
          "lists",
          "advlist",
          "table",
          "image",
          "media",
        ],
        images_upload_url: API_URL + "/image-upload",
        toolbar:
          "emoticons | formatselect | bold italic |  numlist bullist | link |   alignleft aligncenter alignright | image | media | table tabledelete ",
      }}
    />
  );
}
